<style lang="scss">
#attendance-top-bard {
  margin-top: 10px;
  .card {
    .card-body {
      padding: 0;
      display: flex;

      a {
        margin-right: 10px;
      }

      .flex__row {
        display: flex;
        align-items: center;
        min-width: 0;
        padding: 5px;
        border-right: 1px solid #dcdcdc;
        .ml-auto {
          margin-right: -5px;
          .btn:hover {
            background: rgb(236, 232, 232);
          }
          a {
            i {
              font-size: 13px;
              color: #bbb;
              text-align: center;
            }
            padding: 0;
            line-height: 35px;
          }
        }

        .date-show {
          display: inline-flex;
          padding: 0 10px;
          cursor: pointer;
          border: 0.5px solid transparent;

          h2 {
            line-height: 38px;
            margin-bottom: 0 !important;
            margin-right: 10px;
            font-size: 16px;
          }

          a {
            text-align: center;
            line-height: 38px;
            i {
              font-size: 10px;
              color: #6b6666;
            }
          }
          &:hover {
            border-radius: 6.45px;
            border: 0.5px solid #dcdcdc;
            background-color: #fafafa;
            border-radius: 5px;

            a {
              i {
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div id="attendance-top-bard">
    <m-search-popup-box></m-search-popup-box>
    <div class="card mb-5">
      <div class="card-body">
        <div class="flex__row" @click="openModal()">
          <div class="date-show">
            <h2 v-if="getAllAttendanceAttribute.departmentId != ''" class="">
              {{
                getAllAttendanceAttribute.departmentId
                  | departmentName(getDepartments)
              }}
            </h2>
            <h2 v-else class="">Select Department</h2>
            <a href="/my-attendance/statements/month/2021/05/04">
              <i class="flaticon2-sort"></i
            ></a>
          </div>
        </div>

        <div class="flex__row" @click="openModal()">
          <div class="ml-auto">
            <a
              class="btn btn-sm"
              href="/my-attendance/statements/month/2021/03/04"
            >
              <i class="flaticon2-left-arrow"></i> </a
            ><a
              class="btn btn-sm"
              href="/my-attendance/statements/month/2021/05/04"
            >
              <i class="flaticon2-right-arrow"></i
            ></a>
          </div>
          <div class="date-show">
            <h2 class="">
              {{ getAllAttendanceAttribute.month | monthName(getMonths) }}
              {{ getAllAttendanceAttribute.year }}
            </h2>
            <a href="/my-attendance/statements/month/2021/05/04">
              <i class="flaticon2-sort"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import lodash from "lodash";

import SearchPopupBox from "./search_popup_box";
import { SET_ALL_ATTENDANCE_SEARCH_POPUP_BOX } from "@/core/services/store/mutations.type";

// import { mapGetters } from "vuex";
export default {
  components: {
    "m-search-popup-box": SearchPopupBox,
  },
  computed: {
    ...mapGetters(["getAllAttendanceAttribute", "getDepartments", "getMonths"]),
  },

  methods: {
    openModal() {
      this.$store.commit(SET_ALL_ATTENDANCE_SEARCH_POPUP_BOX, true);
    },
  },
  filters: {
    departmentName(id, departments) {
       var name = '';
      lodash.filter(departments, function (o) {
        if(o.id == id){
           name = o.name;
          return name;
        }
      });

     return name;
      
    },
    monthName(index, months) {
      var index_ = index - 1;
      var monthName = months[index_];
      return monthName;
    },
  },
};
</script>
