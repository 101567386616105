<style lang="scss">
.modal-dialog {
  position: relative;
  width: auto;
  margin: 4.5rem auto;
}
#detailpopup___BV_modal_body_ {
  border-radius: 16px;
  position: relative;
  .top-icon {
    box-shadow: 0 1px 8px rgb(0 0 0 / 8%), 0 4px 24px rgb(28 36 43 / 16%);
    width: 64px;
    height: 64px;
    padding: 10px;
    border-radius: 16px;
    position: absolute;
    left: 24px;
    margin-top: -52px;

    background: white;
  }
  i {
    font-size: 12px;
  }
}

.time-in-out-box {
  display: inline-flex;
  .check-in-out {
    display: inline-grid;
    .title {
      font-size: 10px;
    }
    .time {
      font-size: 14px;
    }
  }

  i {
    margin-top: 18px;
    color: rgb(152, 149, 149);
    margin-left: 10px;
    margin-right: 10px;
  }
}

.user-panel {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;

  .avatar {
    background-color: #aaa;
    border-radius: 50%;
    display: inline-block;
    font-weight: 500;
    height: 38px;
    line-height: 38px;
    margin: 0 10px 0 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 38px;
    position: relative;
    white-space: nowrap;
    & > img {
      border-radius: 50%;
      display: block;
      overflow: hidden;
      width: 100%;
    }
  }

  .avatar-xs {
    width: 40px;
    height: 40px;
  }
}

.emp-shift {
  border-top: 2px solid;
  margin-top: 8px;
  /* font-size: 17px; */
      padding: 10px 0px;
  h4 {
    font-size: 14px;
  }
  .shift-day-off {
    display: inline-grid;
    .t {
      font-size: 10px;
    }
    .w {
      font-size: 14px;
    }
  }
}
</style>
<template>
  <b-modal
    no-close-on-backdrop
    hide-header
    hide-footer
    v-model="getAllAttendancePopupDetail.status"
    style="margin-top: 100px"
    ref="detailpopup"
    id="detailpopup"
    ok-title="Save"
    title="Add New Employee's Contract"
  >
    <div class="top-icon">
      <img
        style="margin-left: 5px; margin-top: 6px; width: 40px"
        src="/media/svg/all_attendance_serach.svg"
        alt=""
      />
    </div>
    <div style="margin-top: 40px" v-if="getAllAttendancePopupDetail.attData.length!=0">
      <h4>{{ getAllAttendancePopupDetail.attData.fullDate }}</h4>
      <div class="row"  >
        <div class="col-6">
          <div class="user-panel">
            <a href="/orange/profile" class="avatar avatar-xs"
              ><img alt="" :src="getAllAttendancePopupDetail.empData.avatar"
            /></a>
            <a href="/orange/profile" class="">{{
              getAllAttendancePopupDetail.empData.employee_full_name
            }}</a>
          </div>
          <div class="emp-shift" :style="'border-color:'+getAllAttendancePopupDetail.attData.userShift.color_code">
            <h4>
              {{ getAllAttendancePopupDetail.attData.userShift.shift_title }}
            </h4>
            <div class="time-in-out-box">
              <div class="check-in-out">
                <span class="title">check-in</span>
                <span>{{
                  getAllAttendancePopupDetail.attData.userShift.from_time
                }}</span>
              </div>
              <i class="fa fa-arrow-right"></i>
              <div class="check-in-out">
                <span class="title">check-out</span>
                <span>{{
                  getAllAttendancePopupDetail.attData.userShift.to_time
                }}</span>
              </div>
            </div>
            <div class="shift-day-off">
              <span class="t">Day-off | Weekend</span>
              <span class="w">{{
                getAllAttendancePopupDetail.attData.userShift.weekend
              }}</span>
            </div>
          </div>
        </div>

        <div class="col-6">
           <h4>Attendance</h4>
          <div class="time-in-out-box">
           
            <div class="check-in-out">
              <span class="title">check-in</span>
              <span>{{
                getAllAttendancePopupDetail.attData.attendance.cin
              }}</span>
            </div>
            <i class="fa fa-arrow-right"></i>
            <div class="check-in-out">
              <span class="title">check-out</span>
              <span>{{
                getAllAttendancePopupDetail.attData.attendance.cout != ""
                  ? getAllAttendancePopupDetail.attData.attendance.cout
                  : "--:--"
              }}</span>
            </div>
          </div>
          <p
            v-if="getAllAttendancePopupDetail.attData.attendance.cout == ''"
            style="font-size: 12px; margin-top: 10px"
            class="text-danger"
          >
            <strong>{{
              getAllAttendancePopupDetail.empData.employee_full_name
            }}</strong>
            haven't check on this date
          </p>
        </div>
      </div>

      <button @click="closeModal()" class="btn btn-sm btn-danger float-right">
        <i class="fa fa-close text-sm" style="font-size: 12px"></i> Close
      </button>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ALL_ATTENDANCE_DETAIL_POPUP_BOX } from "@/core/services/store/mutations.type";

export default {
  computed: {
    ...mapGetters(["getAllAttendancePopupDetail"]),
  },
  created() {
    // this.$store.dispatch(FETCH_DEPARTMENTS);
    // this.$store.commit(SET_ALL_ATTENDANCE_DETAIL_POPUP_BOX,true);
  },
  methods: {
    closeModal() {
      this.$store.commit(SET_ALL_ATTENDANCE_DETAIL_POPUP_BOX, {
        status: false,
        empData:this.getAllAttendancePopupDetail.empData,
        attData: this.getAllAttendancePopupDetail.attData,
      });
    },
  },
};
</script>



