
<style lang="scss">
#all-attendance {
  .table {
    background-color: white !important;
  }
  table {
    width: 100%;
    height: 41px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%);
    background: white;
    tr {
      // border: 0.5px solid;
      border-color: #c7c7ca;
      height: 35px;

      td {
        padding: 10p;
        font-weight: 600;
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: #eeeeee;
        }
        &:last-child {
          border-bottom: 0.5px solid;
          border-color: #b5b5c3;
        }

        td {
          cursor: pointer;
          border-top: 0.5px solid;
          border-color: #b5b5c3;

          span {
            i {
              font-size: 14px;

              &:hover {
                font-size: 16px;
              }
            }
          }

          h2 {
            a {
              color: #333;
              font-size: 14px;
            }
          }
          .table-avatar {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            white-space: nowrap;
            font-size: inherit;
            font-weight: 400;
            margin: 0;
            padding: 0;
            vertical-align: middle;
            .avatar {
              background-color: #aaa;
              border-radius: 50%;
              display: inline-block;
              font-weight: 500;
              height: 38px;
              line-height: 38px;
              margin: 0 10px 0 0;
              text-align: center;
              text-decoration: none;
              text-transform: uppercase;
              vertical-align: middle;
              width: 38px;
              position: relative;
              white-space: nowrap;
              & > img {
                border-radius: 50%;
                display: block;
                overflow: hidden;
                width: 100%;
              }
            }

            .avatar-xs {
              width: 30px;
              height: 30px;
            }
          }
        }
        table.table td h2,
        table.table td h2.table-avatar {
          font-size: inherit;
          font-weight: 400;
          margin: 0;
          padding: 0;
          vertical-align: middle;
        }
        table.table td h2 {
          display: inline-block;
        }
      }
    }

    .employee-profile {
      width: 20%;
      text-align: left;
      padding: 10px;
    }
    .indicator-ball {
      padding: 10px;
      text-align: left;
      margin-bottom: 10px;
      display: inline;

      .legn-indi {
        padding: 0 5px;
        span {
          padding: 5px;
          font-size: 12px;
          color: #616161;
          i {
            font-size: 12px;
            margin-right: 5px;
            color: #616161;
          }
        }
      }
    }
  }
}
</style>

<template>
  <section id="all-attendance">
    <v-all-attendance-header></v-all-attendance-header>
    <v-all-attendance-detail-popup></v-all-attendance-detail-popup>

    <div class="row">
      <div class="col-lg-12">
        <table v-if="getAllAttendanceByDepartment != 0">
          <thead>
            <tr>
              <td class="employee-profile" rowspan="2">
                <span style="text-align: center">Employee</span>
              </td>
              <td
                :colspan="getAllAttendanceByDepartment.data.count"
                style="text-align: left; padding: 0 10px"
              >
                <span style="font-size: 12px">Dates</span>
              </td>
            </tr>
            <tr>
              <td
                :style="
                  'width:' + 80 / getAllAttendanceByDepartment.data.count + '%'
                "
                v-for="day in getAllAttendanceByDepartment.data.count"
                :key="day"
              >
                <span>{{ day | doubleDigit }}</span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(empItem, eIndex) in getAllAttendanceByDepartment.data
                .data"
              :key="eIndex"
            >
              <td class="employee-profile">
                <h2 class="table-avatar">
                  <a href="/orange/profile" class="avatar avatar-xs"
                    ><img alt="" :src="empItem.employee.avatar"
                  /></a>
                  <a href="/orange/profile" class="">{{
                    empItem.employee.employee_full_name
                  }}</a>
                </h2>
              </td>
              <td
                v-for="(empAttItem, index) in empItem.attData"
                :key="index"
                :style="'width:' + 80 / empItem.attData.length + '%'"
              >
                <span v-show="empAttItem.isDayOff && !empAttItem.isAbsent" v
                  ><i class="fa fa-circle text-default text-sm"></i
                ></span>
                <!--- weekend or day off condition -->

                <span
                  v-show="
                    !empAttItem.isDayOff &&
                    empAttItem.isAbsent &&
                    empAttItem.holiday == null
                  "
                  ><i class="fa fa-times text-danger text-sm"></i
                ></span>
                <!--- no day off and no holiday but absent condition -->

                <span
                  v-show="
                    !empAttItem.isDayOff &&
                    !empAttItem.isAbsent &&
                    empAttItem.holiday != null
                  "
                  ><i class="fa fa-life-ring text-default"></i>
                </span>
                <!--- if holiday -->

                <span
                  @click="openDetailPopup(empItem.employee, empAttItem)"
                  v-show="
                    !empAttItem.isDayOff &&
                    !empAttItem.isAbsent &&
                    empAttItem.holiday == null &&
                    empAttItem.attendance.length != 0
                  "
                  ><i class="fa fa-check text-success"></i
                ></span>
                <!--- if present -->
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="32">
                <div class="indicator-ball">
                  <div class="legn-indi float-left">
                    <span><i class="fa fa-check"></i>Present</span>
                    <span><i class="fa fa-times"></i>Absent</span>
                    <span><i class="fa fa-life-ring"></i>Holiday</span>
                    <span><i class="fa fa-circle"></i>Day-off</span>
                  </div>

                  <div class="legn-indi float-right">
                    <span
                      ><i class="fa fa-square" :style="'color:' + '#1BC5BD'"></i
                      >Present</span
                    >
                    <span
                      ><i class="fa fa-square" :style="'color:' + '#FFA800'"></i
                      >Late In</span
                    >
                    <span
                      ><i class="fa fa-square" :style="'color:' + '#FFA800'"></i
                      >Early Out</span
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import {
  SET_BREADCRUMB,
  SET_ALL_ATTENDANCE_DETAIL_POPUP_BOX,
} from "@/core/services/store/mutations.type";
import AllAttendanceHeader from "@/components/attendance/all/attendanceheader";
import AllAttendanceDetailPopup from "@/components/attendance/all/attendancedetailpopup.vue";
import { mapGetters } from "vuex";

// import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tWidth: 80 / 10,
    };
  },
  computed: {
    ...mapGetters(["getAllAttendanceByDepartment"]),
  },
  components: {
    "v-all-attendance-header": AllAttendanceHeader,
    "v-all-attendance-detail-popup": AllAttendanceDetailPopup,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Attendance" }]);
  },
  methods: {
    openDetailPopup(empData, attData) {
      this.$store.commit(SET_ALL_ATTENDANCE_DETAIL_POPUP_BOX, {
        status: true,
        empData: empData,
        attData: attData,
      });
    },
  },
  filters: {
    doubleDigit(number) {
      return number > 9 ? number : "0" + number;
    },
  },
};
</script>
