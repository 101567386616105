<style lang="scss">
.modal-dialog {
  position: relative;
  width: auto;
  margin: 4.5rem auto;
}
#searchPopupBox___BV_modal_body_ {
  border-radius: 16px;
  position: relative;
  .top-icon {
    box-shadow: 0 1px 8px rgb(0 0 0 / 8%), 0 4px 24px rgb(28 36 43 / 16%);
    width: 64px;
    height: 64px;
    padding: 10px;
    border-radius: 16px;
    position: absolute;
    left: 24px;
    margin-top: -52px;

    background: white;
  }
  i {
    font-size: 12px;
  }
}
</style>
<template>
  <b-modal
    no-close-on-backdrop
    hide-header
    hide-footer
    v-model="getAllAttendanceSearchPopupBoxFlag"
    style="margin-top: 100px"
    ref="searchPopupBox"
    id="searchPopupBox"
    ok-title="Save"
    title="Add New Employee's Contract"
  >
    <div class="top-icon">
      <img
        style="margin-left: 5px; margin-top: 6px; width: 40px"
        src="/media/svg/all_attendance_serach.svg"
        alt=""
      />
    </div>
    <div style="margin-top: 40px">
      <h4>Search Attendance</h4>
      <!-- <p>Edit shift to set and manage employee's working days and hours.</p> -->
      <div class="row">
        <div class="form-group col-12">
          <label for="">Select Department</label>
          <select
            name=""
            id=""
            class="custom-select"
            @change="updateDepartment"
          >
            <option value="">Select Department</option>
            <option
              v-for="(dItem, index) in getDepartments"
              :key="index"
              :value="dItem.id"
              :selected="getAllAttendanceAttribute.departmentId == dItem.id"
            >
              {{ dItem.name }}
            </option>
          </select>
          <span class="text-danger" v-if="errorMessage!='' && getAllAttendanceAttribute.departmentId=='' ">{{errorMessage}}</span>
        </div>
        <div class="form-group col-6">
          <label for="">Select Year</label>

          <select class="custom-select" @change="updateYear">
            <option
              v-for="(yItem, index) in getYears"
              :key="index"
              :value="yItem"
              :selected="getAllAttendanceAttribute.year == yItem"
            >
              {{ yItem }}
            </option>
          </select>
        </div>
        <div class="form-group col-6">
          <label for="">Select Month</label>

          <select class="custom-select" @change="updateMonth">
            <option
              v-for="(mItem, index) in getMonths"
              :key="index"
              :value="index + 1"
              :selected="getAllAttendanceAttribute.month == index + 1"
            >
              {{ mItem }}
            </option>
          </select>
        </div>
      </div>
      <button
        @click="searchAttendances()"
        class="btn btn-sm btn-success float-right ml-2"
      >
        <i class="fa fa-search text-sm" style="font-size: 12px"></i> Search
      </button>
      <button @click="closeModal()" class="btn btn-sm btn-danger float-right">
        <i class="fa fa-close text-sm" style="font-size: 12px"></i> Close
      </button>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import {
  SET_ALL_ATTENDANCE_SEARCH_POPUP_BOX,
  SET_ALL_ATTENDANCE_SEARCH_DEPARTMENT,
  SET_ALL_ATTENDANCE_SEARCH_YEAR,
  SET_ALL_ATTENDANCE_SEARCH_MONTH,
} from "@/core/services/store/mutations.type";
import {
  FETCH_DEPARTMENTS,
  FETCH_ATTENDANCE_BY_DEPARTMENT,
} from "@/core/services/store/actions.type";

export default {
  data() {
    return {
      errorMessage:''
    }
  },
  computed: {
    ...mapGetters([
      "getAllAttendanceSearchPopupBoxFlag",
      "getDepartments",
      "getMonths",
      "getYears",
      "getAllAttendanceAttribute",
    ]),
  },
  created() {
    this.$store.dispatch(FETCH_DEPARTMENTS);
    this.$store.commit(SET_ALL_ATTENDANCE_SEARCH_POPUP_BOX, true);
  },
  methods: {
    closeModal() {
      this.$store.commit(SET_ALL_ATTENDANCE_SEARCH_POPUP_BOX, false);
    },
    updateDepartment(e) {
      var value = e.target.value;
      this.$store.commit(SET_ALL_ATTENDANCE_SEARCH_DEPARTMENT, value);
    },
    updateYear(e) {
      var value = e.target.value;
      this.$store.commit(SET_ALL_ATTENDANCE_SEARCH_YEAR, value);
    },
    updateMonth(e) {
      var value = e.target.value;
      this.$store.commit(SET_ALL_ATTENDANCE_SEARCH_MONTH, value);
    },
    searchAttendances() {
      if (this.getAllAttendanceAttribute.departmentId != "") {
        this.$store
          .dispatch(FETCH_ATTENDANCE_BY_DEPARTMENT, {
            year: this.getAllAttendanceAttribute.year,
            month: this.getAllAttendanceAttribute.month,
            departmentId: this.getAllAttendanceAttribute.departmentId,
          })
          .then(() => {
            this.closeModal();
          });
      } else {
          this.errorMessage = "Please select Department";
      }
    },
  },
};
</script>



