var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"all-attendance"}},[_c('v-all-attendance-header'),_c('v-all-attendance-detail-popup'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[(_vm.getAllAttendanceByDepartment != 0)?_c('table',[_c('thead',[_c('tr',[_vm._m(0),_c('td',{staticStyle:{"text-align":"left","padding":"0 10px"},attrs:{"colspan":_vm.getAllAttendanceByDepartment.data.count}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Dates")])])]),_c('tr',_vm._l((_vm.getAllAttendanceByDepartment.data.count),function(day){return _c('td',{key:day,style:('width:' + 80 / _vm.getAllAttendanceByDepartment.data.count + '%')},[_c('span',[_vm._v(_vm._s(_vm._f("doubleDigit")(day)))])])}),0)]),_c('tbody',_vm._l((_vm.getAllAttendanceByDepartment.data
              .data),function(empItem,eIndex){return _c('tr',{key:eIndex},[_c('td',{staticClass:"employee-profile"},[_c('h2',{staticClass:"table-avatar"},[_c('a',{staticClass:"avatar avatar-xs",attrs:{"href":"/orange/profile"}},[_c('img',{attrs:{"alt":"","src":empItem.employee.avatar}})]),_c('a',{attrs:{"href":"/orange/profile"}},[_vm._v(_vm._s(empItem.employee.employee_full_name))])])]),_vm._l((empItem.attData),function(empAttItem,index){return _c('td',{key:index,style:('width:' + 80 / empItem.attData.length + '%')},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(empAttItem.isDayOff && !empAttItem.isAbsent),expression:"empAttItem.isDayOff && !empAttItem.isAbsent"}],attrs:{"v":""}},[_c('i',{staticClass:"fa fa-circle text-default text-sm"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                  !empAttItem.isDayOff &&
                  empAttItem.isAbsent &&
                  empAttItem.holiday == null
                ),expression:"\n                  !empAttItem.isDayOff &&\n                  empAttItem.isAbsent &&\n                  empAttItem.holiday == null\n                "}]},[_c('i',{staticClass:"fa fa-times text-danger text-sm"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                  !empAttItem.isDayOff &&
                  !empAttItem.isAbsent &&
                  empAttItem.holiday != null
                ),expression:"\n                  !empAttItem.isDayOff &&\n                  !empAttItem.isAbsent &&\n                  empAttItem.holiday != null\n                "}]},[_c('i',{staticClass:"fa fa-life-ring text-default"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                  !empAttItem.isDayOff &&
                  !empAttItem.isAbsent &&
                  empAttItem.holiday == null &&
                  empAttItem.attendance.length != 0
                ),expression:"\n                  !empAttItem.isDayOff &&\n                  !empAttItem.isAbsent &&\n                  empAttItem.holiday == null &&\n                  empAttItem.attendance.length != 0\n                "}],on:{"click":function($event){return _vm.openDetailPopup(empItem.employee, empAttItem)}}},[_c('i',{staticClass:"fa fa-check text-success"})])])})],2)}),0),_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":"32"}},[_c('div',{staticClass:"indicator-ball"},[_vm._m(1),_c('div',{staticClass:"legn-indi float-right"},[_c('span',[_c('i',{staticClass:"fa fa-square",style:('color:' + '#1BC5BD')}),_vm._v("Present")]),_c('span',[_c('i',{staticClass:"fa fa-square",style:('color:' + '#FFA800')}),_vm._v("Late In")]),_c('span',[_c('i',{staticClass:"fa fa-square",style:('color:' + '#FFA800')}),_vm._v("Early Out")])])])])])])]):_vm._e()])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"employee-profile",attrs:{"rowspan":"2"}},[_c('span',{staticStyle:{"text-align":"center"}},[_vm._v("Employee")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"legn-indi float-left"},[_c('span',[_c('i',{staticClass:"fa fa-check"}),_vm._v("Present")]),_c('span',[_c('i',{staticClass:"fa fa-times"}),_vm._v("Absent")]),_c('span',[_c('i',{staticClass:"fa fa-life-ring"}),_vm._v("Holiday")]),_c('span',[_c('i',{staticClass:"fa fa-circle"}),_vm._v("Day-off")])])}]

export { render, staticRenderFns }